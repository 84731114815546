import { AnalyticsConfiguration } from './analytics-configuration';
import {
  getAnalyticsConfiguration,
  interactionEvent, pageViewEvent, interactionWithPageViewEvents,
  selfServeStartWithPageViewEvents, selfServeStartAndInteractionWithPageViewEvents,
  selfServeCompleteEvent
} from './global-analytics-configuration';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AnalyticsServiceProxy } from './analytics-service-proxy.service';

export enum SiteNames {
  rogers = 'R',
  fido = 'F',
  r4b = 'R-Biz',
  rogersApp = 'MyRogersApp',
  fidoApp = 'MyAccountApp',
  chatr = 'chatr',
  rshm = 'R',
  xfi = 'R',
  ignitetv = 'R',

  /*media = 'RSM'
  rogersApp = 'MyRogersApp',
  fidoApp = 'MyAccountApp',
  igniteTV = 'R',
  igniteWifi = 'R',
  smarthomemonitoring = 'R',*/
}

export const clientMapping = {
  // brand = [hierarchyLevel1, sign in type]
  rogers: {
    hierLevel1: 'myrogers',
    signinType: 'MyRogers|EAS',
  },
  rogersApp: {
    hierLevel1: 'myrogersApp',
    signinType: 'MyRogersApp|EAS',
  },
  fido: {
    hierLevel1: 'myaccount',
    signinType: 'MyAccountApp|EAS',
  },
  fidoApp: {
    hierLevel1: 'myaccountApp',
    signinType: 'MyAccountApp|EAS',
  },
  r4b: {
    hierLevel1: 'selfserve',
    signinType: 'Login-BSS-S|EAS',
  },
  chatr: {
    hierLevel1: 'signin',
    signinType: 'chatr|EAS',
  },
  rshm: {
    hierLevel1: 'smarthomemonitoringapp',
    signinType: 'Smarthome|EAS',
  },
  xfi: {
    hierLevel1: 'ignitewifi',
    signinType: 'IgniteWifi|EAS',
  },
  ignitetv: {
    hierLevel1: 'ignite iptv',
    signinType: 'IgniteTV|EAS',
  },
  other: {
    hierLevel1: 'other',
    signinType: 'other|EAS',
  },


  /*rogersApp: ['authentication', 'MyRogersApp'],
  fidoApp: ['authentication', 'MyAccountApp'],
  igniteTV: ['ignite iptv', 'Ignite IPTV'],
  igniteWifi: ['ignitewifi', 'IgniteWifi'],
  smarthomemonitoring: ['smarthomemonitoringapp', 'Smarthome'],*/
};


function getCookie(cookieName) {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(cookieName + '=')) {
      return cookie.substring(cookieName.length + 1);
    }
  }

  return null;
}

// Brand
let brand = getCookie('brand');
// console.log(brand);
const igniteBrand = getCookie('igniteBrand');
// console.log(igniteBrand);

// we need seperate analytics for the below brands
if (igniteBrand === 'rshm' || igniteBrand === 'xfi' || igniteBrand === 'ignitetv'){
  brand = igniteBrand;
}
// let brand = localStorage.getItem('brand');
// const igniteBrand = localStorage.getItem('igniteBrand');

const brandMapping = clientMapping[brand];
const hierarchyLevel1 = brandMapping && brandMapping['hierLevel1'];

// Hierarchy Array
export const flowMapping = {
  forgotusername: 'recover-username',
  forgotpassword: 'recover-password' ,
  register: ['registration', 'self-registration'],
  autoreg: ['registration', 'auto-registration'],
  login: 'signin'
};

const loginHierarchy = [hierarchyLevel1, 'signin'];
const usernameHierarchy = [hierarchyLevel1, 'recover-username'];
const passwordHierarchy = [hierarchyLevel1, 'recover-password'];
const selfRegistrationHierarchy = [hierarchyLevel1, 'registration']; // Level3 for Self vs Auto Reg
const changeUsernameHierarchy = [hierarchyLevel1, 'profile-settings', 'profile-security', 'update-username'];
const changePasswordHierarchy = [hierarchyLevel1, 'profile-settings', 'profile-security', 'update-password'];
const changeMobileHierarchy = [hierarchyLevel1, 'profile-settings', 'profile-security', 'sms-recovery'];
const selectAuthenticatonFactorsHierarchy = [hierarchyLevel1, 'profile-settings', 'profile-security', 'update MFA settings'];
const setAuthenticatonFactorsHierarchy = [hierarchyLevel1, 'signin'];
const registrationHierarchy = [hierarchyLevel1, 'registration', 'self-registration']; // OTP page

const mainHierarchy = [hierarchyLevel1];

// Page Names
const usernameInput = 'username-input';
const passwordInput = 'password-input';
const emailAlreadyRegistered = 'email-already-registered';
const findUsername = 'find-username-with-email';
const otpMethodSelection = 'mfa-method-selection';
const otpInput = 'otp-verification-code';
const setNewPassword = 'set-new-password';
const changeUsernameInput = 'enter-new-username';
const changeUsernameConfirm = 'confirm-new-username';
const changeUsernameOtp = 'email-verification-code';
const changePasswordInput = 'enter-new-password';
const changePasswordConfirm = 'confirm-new-password';
const changeMobileInput = 'enter-new-recovery-number';
const changeMobileConfirm = 'confirm-new-number';
const changeMobileOtp = 'sms-verification-code';
const selectAuthenticatonFactors = 'modal-select-authenticaton factors';
const updateAuthenticatonFactors = 'modal-update MFA success';
const setAuthenticatonFactorsAll = 'modal-enable mfa|wireless number|email address';
const setAuthenticatonFactorsEmail = 'modal-enable mfa|email address';
const setAuthenticatonFactorsNumber = 'modal-enable mfa|wireless number';
const setAuthenticatonFactorsNone = 'modal-enable mfa';
const mfaContactUs = 'modal-contact us mfa';




// Self Serve
const transaction = 'transaction';
const changeUsernameTransaction = 'change username';
const changePasswordTransaction = 'change password';
const changeMobileTransaction = 'sms recovery setup';
const selectAuthenticatonFactorsTransaction = 'update MFA settings';
const setAuthenticatonFactorsTransaction = 'mfa enrollment';

// Interactions
const click = 'click';
const resendCodeInteraction = {
  type: click,
  name: 'resend code'
};
const mfaEnrollmentClickInteraction = {
  type: 'click',
  name: 'mfa-enable'
};
const mfaEnrollmentSkipInteraction = {
  type: 'click',
  name: 'mfa-skip'
};

const analyticsConfigWithPageEvents = {
  'username-input': [
    usernameInput,
    loginHierarchy
  ],
  'email-already-registered': [
    emailAlreadyRegistered,
    selfRegistrationHierarchy
  ],
  'password-input': [
    passwordInput,
    loginHierarchy
  ],
  'signin-attempt': [
    passwordInput,
    loginHierarchy,
    null,
    null,
    { signinAttempt: true }
  ],
  'find-username-with-email': [
    findUsername,
    usernameHierarchy,
  ],
  'otp-method-selection': [
    otpMethodSelection,
    mainHierarchy,
    undefined,
    undefined,
    pageViewEvent
  ],
  'otp-input': [
    otpInput,
    registrationHierarchy
  ],
  'otp-resend-code': [
    otpInput,
    registrationHierarchy,
    null,
    null,
    interactionEvent,
    resendCodeInteraction
  ],
  'set-password': [
    setNewPassword,
    mainHierarchy,
    undefined,
    undefined,
    pageViewEvent
  ],
  'set-password-signin-attempt': [
    setNewPassword,
    mainHierarchy,
    null,
    null,
    { signinAttempt: true }
  ],
  'change-username-input': [
    changeUsernameInput,
    changeUsernameHierarchy,
    changeUsernameTransaction,
    transaction,
    selfServeStartWithPageViewEvents
  ],
  'change-username-errors': [
    changeUsernameInput,
    changeUsernameHierarchy,
  ],
  'change-username-confirm': [
    changeUsernameConfirm,
    changeUsernameHierarchy
  ],
  'change-username-otp': [
    changeUsernameOtp,
    changeUsernameHierarchy
  ],
  'change-username-otp-resend-code': [
    changeUsernameOtp,
    changeUsernameHierarchy,
    null,
    null,
    interactionEvent,
    resendCodeInteraction
  ],
  'change-password-input': [
    changePasswordInput,
    changePasswordHierarchy,
    changePasswordTransaction,
    transaction,
    selfServeStartWithPageViewEvents
  ],
  'change-password-errors': [
    changePasswordInput,
    changePasswordHierarchy,
  ],
  'change-password-confirm': [
    changePasswordConfirm,
    changePasswordHierarchy
  ],
  'change-mobile-input': [
    changeMobileInput,
    changeMobileHierarchy,
    changeMobileTransaction,
    transaction,
    selfServeStartWithPageViewEvents
  ],
  'change-mobile-errors': [
    changeMobileInput,
    changeMobileHierarchy,
  ],
  'change-mobile-confirm': [
    changeMobileConfirm,
    changeMobileHierarchy
  ],
  'change-mobile-otp': [
    changeMobileOtp,
    changeMobileHierarchy
  ],
  'change-mobile-otp-resend-code': [
    changeMobileOtp,
    changeMobileHierarchy,
    null,
    null,
    interactionEvent,
    resendCodeInteraction
  ],
  'modal-select-authenticaton factors': [
    selectAuthenticatonFactors,
    selectAuthenticatonFactorsHierarchy,
    selectAuthenticatonFactorsTransaction,
    transaction,
    selfServeStartWithPageViewEvents
  ],
  'modal-update MFA success': [
    updateAuthenticatonFactors,
    selectAuthenticatonFactorsHierarchy
  ],
  'modal-mfa-all': [
    setAuthenticatonFactorsAll,
    setAuthenticatonFactorsHierarchy
  ],
  'modal-mfa-email': [
    setAuthenticatonFactorsEmail,
    setAuthenticatonFactorsHierarchy
  ],
  'modal-mfa-number': [
    setAuthenticatonFactorsNumber,
    setAuthenticatonFactorsHierarchy
  ],
  'modal-mfa-none': [
    setAuthenticatonFactorsNone,
    setAuthenticatonFactorsHierarchy
  ],
  'enable-mfa-enrollment-all-nudge': [
    setAuthenticatonFactorsAll,
    setAuthenticatonFactorsHierarchy,
    "mfa enrollment nudge",
    "transaction",
    selfServeStartAndInteractionWithPageViewEvents,
    mfaEnrollmentClickInteraction
  ],
  'enable-mfa-enrollment-all-force': [
    setAuthenticatonFactorsAll,
    setAuthenticatonFactorsHierarchy,
    "mfa enrollment force",
    "transaction",
    selfServeStartAndInteractionWithPageViewEvents,
    mfaEnrollmentClickInteraction
  ],
  'skip-mfa-enrollment-all': [
    setAuthenticatonFactorsAll,
    setAuthenticatonFactorsHierarchy,
    'mfa enrollment',
    'transaction',
    interactionWithPageViewEvents,
    mfaEnrollmentSkipInteraction
  ],
  'enable-mfa-enrollment-email-nudge': [
    setAuthenticatonFactorsEmail,
    setAuthenticatonFactorsHierarchy,
    'mfa enrollment nudge',
    'transaction',
    selfServeStartAndInteractionWithPageViewEvents,
    mfaEnrollmentClickInteraction
  ],
  'enable-mfa-enrollment-email-force': [
    setAuthenticatonFactorsEmail,
    setAuthenticatonFactorsHierarchy,
    'mfa enrollment force',
    'transaction',
    selfServeStartAndInteractionWithPageViewEvents,
    mfaEnrollmentClickInteraction
  ],
  'skip-mfa-enrollment-email': [
    setAuthenticatonFactorsEmail,
    setAuthenticatonFactorsHierarchy,
    'mfa enrollment',
    'transaction',
    interactionWithPageViewEvents,
    mfaEnrollmentSkipInteraction
  ],
  'enable-mfa-enrollment-number-nudge': [
    setAuthenticatonFactorsNumber,
    setAuthenticatonFactorsHierarchy,
    'mfa enrollment nudge',
    'transaction',
    selfServeStartAndInteractionWithPageViewEvents,
    mfaEnrollmentClickInteraction
  ],
  'enable-mfa-enrollment-number-force': [
    setAuthenticatonFactorsNumber,
    setAuthenticatonFactorsHierarchy,
    'mfa enrollment force',
    'transaction',
    selfServeStartAndInteractionWithPageViewEvents,
    mfaEnrollmentClickInteraction
  ],
  'skip-mfa-enrollment-number': [
    setAuthenticatonFactorsNumber,
    setAuthenticatonFactorsHierarchy,
    'mfa enrollment',
    'transaction',
    interactionWithPageViewEvents,
    mfaEnrollmentSkipInteraction
  ],
  'enable-mfa-enrollment-none-nudge': [
    setAuthenticatonFactorsNone,
    setAuthenticatonFactorsHierarchy,
    'mfa enrollment nudge',
    'transaction',
    interactionWithPageViewEvents,
    mfaEnrollmentClickInteraction
  ],
  'enable-mfa-enrollment-none-force': [
    setAuthenticatonFactorsNone,
    setAuthenticatonFactorsHierarchy,
    'mfa enrollment force',
    'transaction',
    interactionWithPageViewEvents,
    mfaEnrollmentClickInteraction
  ],
  'skip-mfa-enrollment-none': [
    setAuthenticatonFactorsNone,
    setAuthenticatonFactorsHierarchy,
    'mfa enrollment',
    'transaction',
    interactionWithPageViewEvents,
    mfaEnrollmentSkipInteraction
  ],
  'modal-contactus-mfa': [
    mfaContactUs,
    setAuthenticatonFactorsHierarchy
  ]
};


@Injectable()
export class EasAnalyticsConfiguration implements AnalyticsConfiguration {
  mappings = {
    ...Object.keys(analyticsConfigWithPageEvents).reduce((analyticsObject, a) => {
      const payload = analyticsConfigWithPageEvents[a];
      return {
        ...analyticsObject,
        [a]: getAnalyticsConfiguration({
          pageName: payload[0],
          pageHierarchy: payload[1],
          selfServeName: payload[2],
          selfServeType: payload[3],
          events: payload[4],
          interaction: payload[5]
        }),
      };
    }, {}),
  };
}
