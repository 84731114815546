import {AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild, ViewChildren} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';

import {
  ConfigurationService
} from '../shared/configuration.service';
import { CookieService } from 'ngx-cookie-service';
import { CodeInputComponent } from '@rogers/cdk/form';
import { CounterDirective } from '../shared/counter.directive';
import { LiveChatService } from '../livechat/livechat.service';

interface FormattedOTPTarget {
    channel: string;
    targetDescription: string;
}

@Component({
    selector: 'otp-waiting-for-input',
    templateUrl: './otpWaitingForInput.component.html',
    styleUrls: ['./otpWaitingForInput.component.scss']
})
export class OTPWaitingForInput implements OnInit, AfterViewInit {

  otpLength = 6;
  optTargetDescription = '';
  otp = '';
  formSubmitted = false;
  resendCodeClick = false;
  resendDisabled = true;
  verifyUserForm: UntypedFormGroup;
  public verifyType: VerifyType;
  selectedTargetType: com.ts.mobile.sdk.OtpTarget;
  private readonly windowObj: any;
  @ViewChild('codeInputRef') codeInputRef: CodeInputComponent;
  otpFormError = false;
  @ViewChild('continueBtn', { read: ElementRef, static: true })
  loginBtn: ElementRef;
  @ViewChildren(CounterDirective) CounterDirective;
  targets: Array<com.ts.mobile.sdk.AuthenticatorTarget>;
  formattedTargets: FormattedOTPTarget[] = [];

  @Output() onValueChange = new EventEmitter<any>();
  inProgress = false;
  brand = '';


  public onChangeEmail: () => void;

  counter: number;
  counterInitialValue: number;
  altType: string;
  showHelp = false;
  showPhoneLink = false;
  showEmailLink = false;
  selectedChannel: any;
  isSwitched = false;
  maxOtpAttemptsTried = false;
  incorrectCode = false;
  expiredCode = false;
  targetsCount = 0;
  otherError = false;
  showChangeEmailLink = false;
  analyticsErrorCode = '';

  track = {
    otpInputPageView: {
      pageView: true,
      isModalPopup: false,
      mappingKey: 'otp-input',
      additionalPayload: {
        miscellaneous: {
          type: 'otp-verification-code-type',
          name: ''
        }
      }
    },
    otpInputApiErrors: {
      pageView: false,
      isModalPopup: false,
      mappingKey: 'otp-input',
      category: 'self serve',
      type: 'http',
      code: this.analyticsErrorCode,
      API: this.configurationService.config.analyticsAPIUrl,
      field: ''
    },
    otpInputFormError: {
      pageView: false,
      isModalPopup: false,
      mappingKey: 'otp-input',
      category: 'self serve',
      type: 'form',
      code: '',
      API: '',
      field: ''
    },
    otpResendCode: {
      pageView: false,
      isModalPopup: false,
      mappingKey: 'otp-resend-code'
    }
  };

  public onSubmitCode: (code: string) => void;
  public onTargetSelected: (target: com.ts.mobile.sdk.AuthenticatorTarget) => void;
  public onResend: () => void;

  constructor(
    private readonly translate: TranslateService,
    private readonly titleService: Title,
    private readonly configurationService: ConfigurationService,
    private readonly cookieService: CookieService,
    private readonly liveChatService: LiveChatService,
  ) {
    this.windowObj = !!window.frameElement
      ? (window.parent as any)
      : (window as any);
  }

  public setWaitingInfo = (length: number, targetDescription: string) => {
    this.otpLength = length;
    this.optTargetDescription = targetDescription;
  }

  ngOnInit() {
    const element = document.getElementById("transmitContainer");
    element.classList.add("hideTransmitContainer");
    this.inProgress = false;
    this.incorrectCode = false;
    this.otherError = false;
    const timer = this.cookieService.get('timer');
    if (timer){
      this.counterInitialValue = +timer;
    }
    else{
      this.counterInitialValue = 60;
    }
    // this.initForm();
    const channelSwitched = this.cookieService.get('switched');
    const channel = this.selectedTargetType.getChannel();
    this.formatTargets();
    const regFlow = this.cookieService.get('regFlow');
    console.log('regFlow: ', regFlow);

    if (regFlow === 'SELF_REG' || regFlow === 'create'){
      this.showChangeEmailLink = true;
    }
    else if (regFlow === 'password'){
      this.showChangeEmailLink = false;
    }
    else{
      this.showChangeEmailLink = false;
    }

    if (channel === 2 ){
      this.verifyType = VerifyType.Email;
      this.altType = 'email';
      this.showEmailLink = false;
      this.showPhoneLink = this.targetsCount === 2 ? true : false;
      this.selectedChannel = 1;
      this.track.otpInputPageView.additionalPayload.miscellaneous.name = 'email';
      this.track.otpInputFormError.field = 'email verification code';
    }
    else{
      this.verifyType = VerifyType.Sms;
      this.altType = 'text';
      this.showEmailLink = this.targetsCount === 2 ? true : false;;
      this.showPhoneLink = false;
      this.selectedChannel = 0;
      this.track.otpInputPageView.additionalPayload.miscellaneous.name = 'sms';
      this.track.otpInputFormError.field = 'sms verification code';
    }

    if (channelSwitched === 'true'){
      this.showEmailLink = false;
      this.showPhoneLink = false;
      this.showHelp = true;
    }

    if (channelSwitched === 'false' || channelSwitched === null){
      this.showHelp = this.targetsCount === 2 ? false : true;
    }
    const brand = this.cookieService.get('client');
    this.brand = brand;

    console.log('brand in otp page - ', brand);
    if (brand === 'r4b' || brand === 'yahoo' || brand === 'chatr'){
      this.showHelp = false;
    }

    if (brand === 'yahoo'){
      this.showEmailLink = false;
      this.showChangeEmailLink = false;
    }

    this.verifyUserForm = new UntypedFormGroup(
      {
        otp: new UntypedFormControl('', [Validators.required])
      },
      { updateOn: 'submit' }
    );

    let client = this.cookieService.get('brand');

    if (brand === 'media'){
      client = 'media';
    }

    setTimeout(() => {
      if (this.cookieService.get('title') === 'forgotpassword')
        {
          this.titleService.setTitle(
            this.translate.instant(client + '.resetPassword.browserTitle')
          );
        }
        else if (this.cookieService.get('title') === 'forgotusername')
        {
          this.titleService.setTitle(
           this.translate.instant(client + '.forgotUsername.browserTitle')
          );
        }
        else if (regFlow === 'SELF_REG' || regFlow === 'create')
        {
          this.titleService.setTitle(
            this.translate.instant(client + '.registration.browserTitle')
          );
        }
        else
        {
          this.titleService.setTitle(
            this.translate.instant(client + '.login.browserTitle')
          );
        }
    }, 500);

    console.log('show help in otp page - ', this.showHelp);
  }

  ngAfterViewInit() {
    this.codeInputRef.inputContainersList.forEach((element, i) => {
      element.nativeElement
        .querySelector('input')
        .setAttribute(
          'title',
          this.translate.instant('rogers.verifyEmail.verificationInput')
        );
    });

    this.codeInputRef.inputsList.first.nativeElement.focus();
  }

  onSubmit = () => {
    const val = this.counter;

    this.formSubmitted = true;

    this.incorrectCode = false;
    this.otherError = false;
    const isValid = this.otpFieldIsInvalid;
    if (isValid) {
      this.inProgress = true;
      this.codeInputRef.inputsList.first.nativeElement.focus();
      this.cookieService.set('timer', JSON.stringify(this.counter),undefined, undefined, null, true, 'None');
      this.onSubmitCode(this.otp);
    }
    this.codeInputRef.inputsList.first.nativeElement.focus();
  }

  onResendCode = () => {
    this.resendDisabled = true;
    this.resendCodeClick = true;
    this.otherError = false;
    console.log('OTPWaitingForInput - onResend');
    this.onResend();
    console.log(`OTPWaitingForInput - onResend:${this.codeInputRef}`);
  }

  onCancel = () => {
    console.log(`PasswordFormSession - onCancel:${this.codeInputRef}`);
  }

  keyDownEnter(event: any) {
    event.preventDefault();
    this.loginBtn.nativeElement.click();
  }

  onCodeChanged() {
    this.reset();
  }

  onCodeCompleted(code: string) {
    this.otp = code;
  }

  onLiveChat() {
    this.liveChatService.triggerChat('accountRecovery');
  }

  private reset() {
    this.otp = '';
    this.formSubmitted = false;
  }

  get otpFieldIsInvalid() {
    if (this.otp === '' || this.otp.length !== 6){
      return false;
    }
    return true;
  }

  onCounterChange(e): void {
    this.counter = e;
    this.resendDisabled = true;
    if (this.counter === 0) {
      this.resendDisabled = false;
    }
  }

  onSendCodeByDiffentChannel(id: number){
    this.onTargetSelected(this.targets[id]);
    this.resetCounter();
    this.codeInputRef.clear();
    this.formSubmitted = false;
    this.codeInputRef.inputsList.first.nativeElement.focus();
    this.showEmailLink = false;
    this.showPhoneLink = false;
    this.isSwitched = true;
    this.cookieService.set('switched', 'true',undefined, undefined, null, true, 'None');
  }

  resetCounter() {
    this.CounterDirective.first.startTimer();
    this.resendDisabled = true;
  }

  resetErrorMessage(): void {
    this.maxOtpAttemptsTried = false;
    this.incorrectCode = false;
    this.otherError = false;
  }

  removeTimer(): void {
    this.resendDisabled = true;
    this.counter = 0;
  }

  setErrorCode(error: string){
   // this.formSubmitted = true;
    this.cookieService.delete('timer');
    console.log('error :', error);
    this.maxOtpAttemptsTried = false;
    this.incorrectCode = false;
    this.otherError = false;

    if (error === "locked")
    {
      this.maxOtpAttemptsTried = true;
      this.removeTimer();
      this.track.otpInputApiErrors.type = 'user';
      this.track.otpInputApiErrors.code = 'verifySms.error.maxOtpAttemptsTried';
    }
    else if (error === "invalid")
    {
      this.incorrectCode = true; // Incorrect PIN entered
      this.track.otpInputApiErrors.type = 'user';
      this.track.otpInputApiErrors.code = 'verifySms.error.incorrectCode';
     this.resendDisabled = false;
    }
    else
    {
      this.otherError = true; // some other error
      this.track.otpInputApiErrors.type = 'http';
      this.track.otpInputApiErrors.code = 'verifySms.error.system';
    }
  }

  OnChangeEmailClick(){
    this.onChangeEmail();
  }

  private formatTargets = (): void => {
    const formatted: FormattedOTPTarget[] = [];
    this.targets.forEach((target: com.ts.mobile.sdk.OtpTarget) => {
        formatted.push({
            channel: this.getChannelName(target.getChannel()),
            targetDescription: target.getDescription()
        });
    });
    this.formattedTargets = formatted.filter(i => i.targetDescription !== 'null')
                  .sort((a, b) => b.channel.localeCompare(a.channel));

    this.targetsCount = this.formattedTargets.length;
}

private getChannelName = (channel: com.ts.mobile.sdk.OtpChannel): string => {
  switch (channel) {
      case 1: return 'Sms';
      case 2: return 'Email';
      case 3: return 'PushNotification';
      case 4: return 'VoiceCall';
      default: return 'Unknown';
  }
}

  ngOnDestroy(): void {}
}

enum VerifyType {
  Email = 'verifyEmail',
  Sms = 'verifySms',
  WirelessSms = 'wirelessSms',
  ChangeUsername = 'changeUsername'
}
