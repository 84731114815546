import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { CodeInputComponent } from '@rogers/cdk/form';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { LiveChatService } from 'src/app/livechat/livechat.service';
import { CounterDirective } from 'src/app/shared/counter.directive';
import { ConfigurationService } from 'src/app/shared/configuration.service';
import { CookieService } from 'ngx-cookie-service';


@Component({
  selector: 'app-otp-confirmation',
  templateUrl: './otp-confirmation.component.html',
  styleUrls: ['./otp-confirmation.component.scss']
})
export class OtpConfirmationComponent implements OnInit, AfterViewInit {

  otpLength = 6;
  optTargetDescription = '';
  otp = '';
  formSubmitted = false;
  resendCodeClick = false;
  resendDisabled = true;
  verifyUserForm: UntypedFormGroup;
  verifyType = 'verifyEmail';
  selectedTargetType: com.ts.mobile.sdk.OtpTarget;
  private readonly windowObj: any;
  @ViewChild('codeInputRef') codeInputRef: CodeInputComponent;
  otpFormError = false;
  @ViewChild('continueBtn', { read: ElementRef, static: true })
  continueBtn: ElementRef;
  @ViewChildren(CounterDirective) CounterDirective;
  targets: Array<com.ts.mobile.sdk.AuthenticatorTarget>;

  email: string;
  mobile: string;

  loginStart = false;
  loginInProgress = false;
  counter: number;
  counterInitialValue: number;
  altType: string;

  maxOtpAttemptsTried = false;
  incorrectCode = false;
  expiredCode = false;
  targetsCount = 0;
  otherError = false;

  showHelp = true;
  brand='';
  track = {
    changeUsernameOtpPageview: {
      pageView: true,
      isModalPopup: false,
      mappingKey: 'change-username-otp',
    },
    changeUsernameOtpErrors: {
      pageView: false,
      isModalPopup: false,
      mappingKey: 'change-username-otp',
      category: 'self serve',
      type: 'http',
      code: '',
      API: this.configurationService.config.analyticsAPIUrl,
      field: ''
    },
    changeUsernameOtpFormError: {
      pageView: false,
      isModalPopup: false,
      mappingKey: 'change-username-otp',
      category: 'self serve',
      type: 'form',
      code: '',
      API: '',
      field: 'email verification code'
    },
    changeUsernameOtpResendCode: {
      pageView: false,
      isModalPopup: false,
      mappingKey: 'change-username-otp-resend-code'
    }
  };

  public onSubmitCode: (code: string) => void;
  public onResend: () => void;
  public onChangeUserEmail: () => void;

  constructor(
    private readonly configurationService: ConfigurationService,
    private readonly translate: TranslateService,
    private readonly titleService: Title,
    private readonly liveChatService: LiveChatService,
    private readonly cookieService: CookieService
  ) {
    this.windowObj = !!window.frameElement
      ? (window.parent as any)
      : (window as any);
  }

  public setWaitingInfo = (length: number, targetDescription: string) => {
    this.otpLength = length;

    if (this.email !== '' && this.email !== undefined && this.email !== null){
      this.optTargetDescription  = this.email;
    }
    else{
      this.optTargetDescription = targetDescription;
    }

  }

  ngOnInit() {
    // const client = this.cookieService.get('brand');
    let client = this.cookieService.get('brand');
    const brand = this.cookieService.get('client');
    this.brand = brand;

    if (brand === 'media'){
      client = 'media';
    }
    this.cookieService.set('profilePage', 'changeUsername',undefined, undefined, null, true, 'None');

    var element = document.getElementById("transmitContainer");
    element.classList.add("hideTransmitContainer");
    this.loginStart = true;
    const timer = this.cookieService.get('username_timer');
    if(timer){
      this.counterInitialValue = +timer;
    }
    else{
      this.counterInitialValue = 60;
    }

    this.verifyUserForm = new UntypedFormGroup(
      {
        otp: new UntypedFormControl('', [Validators.required])
      },
      { updateOn: 'submit' }
    );

    this.translate.onLangChange
      .pipe(untilDestroyed(this))
      .subscribe((langEvent: LangChangeEvent) => {
        this.titleService.setTitle(
          this.translate.instant(client + '.login.browserTitle')
        );
      });
    setTimeout(() => {
      this.titleService.setTitle(
        this.translate.instant(client + '.login.browserTitle')
      );
    }, 500);

    // const brand = this.cookieService.get('client');
    console.log('brand in change username otp page - ', brand);
    if (brand === 'r4b' || brand === 'yahoo'){
      this.showHelp = false;
    }

    console.log('show help change username otp page - ', this.showHelp);
  }

  ngAfterViewInit(): void {
    this.codeInputRef.inputContainersList.forEach((element, i) => {
      element.nativeElement
        .querySelector('input')
        .setAttribute(
          'title',
          this.translate.instant('rogers.verifyEmail.verificationInput')
        );
    });
    this.codeInputRef.inputsList.first.nativeElement.focus();
  }

  onSubmit = () => {
    this.formSubmitted = true;
    const isValid = this.otpFieldIsInvalid;

    if (isValid) {
      this.codeInputRef.inputsList.first.nativeElement.focus();
      this.cookieService.set('username_timer', JSON.stringify(this.counter),undefined, undefined, null, true, 'None');
      this.onSubmitCode(this.otp);
    }
    this.codeInputRef.inputsList.first.nativeElement.focus();
  }

  onResendCode = () => {
    this.resendDisabled = true;
    this.resendCodeClick = true;
    console.log('OTPWaitingForInput - onResend');
    this.onResend();
    console.log(`OTPWaitingForInput - onResend:${this.codeInputRef}`);
  }

  onCancel = () => {
    console.log(`PasswordFormSession - onCancel:${this.codeInputRef}`);
  }

  keyDownEnter(event: any) {
    event.preventDefault();
    this.continueBtn.nativeElement.click();
  }

  onCodeChanged() {
    this.reset();
  }

  onCodeCompleted(code: string) {
    this.otp = code;
  }

  onLiveChat() {
    this.liveChatService.triggerChat('accountRecovery');
  }

  private reset() {
    this.otp = '';
    this.formSubmitted = false;
  }

  get otpFieldIsInvalid() {
    if (this.otp === '' || this.otp.length !== 6){
      return false;
    }
    return true;
  }

  onCounterChange(e): void {
    this.counter = e;
    this.resendDisabled = true;
    if (this.counter === 0) {
      this.resendDisabled = false;
    }
  }

  resetCounter() {
    this.CounterDirective.first.startTimer();
    this.resendDisabled = true;
  }

  setErrorCode(error: string){
    //  this.formSubmitted = true;
    this.cookieService.delete('username_timer');
    this.maxOtpAttemptsTried = false;
    this.incorrectCode = false;
    this.otherError = false;

    if (error === 'locked')
      {
        this.maxOtpAttemptsTried = true;
        this.removeTimer();
        this.track.changeUsernameOtpErrors.type = 'user';
        this.track.changeUsernameOtpErrors.code = 'verifySms.error.maxOtpAttemptsTried';
      }
    else if (error === 'invalid')
      {
        this.incorrectCode = true; // Incorrect PIN entered
        this.track.changeUsernameOtpErrors.type = 'user';
        this.track.changeUsernameOtpErrors.code = 'verifySms.error.incorrectCode';
        this.resendDisabled =false;
      }
    else
      {
        this.otherError = true; // some other error
        this.track.changeUsernameOtpErrors.type = 'http';
        this.track.changeUsernameOtpErrors.code = 'verifySms.error.incorrectCode';
      }
    }

    removeTimer(): void {
      this.resendDisabled = true;
      this.counter = 0;
    }

    resetErrorMessage(): void {
      this.maxOtpAttemptsTried = false;
      this.incorrectCode = false;
      this.otherError = false;
    }

  onChangeEmail(){
    this.onChangeUserEmail();
  }

  ngOnDestroy(): void {}

}
